<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-8 offset-sm-2">
        <div>
          <form @submit.prevent="handleSubmit">
            <div class="form-group">
              <label for="Username">Username</label>
              <input
                type="text"
                v-model="user.username"
                id="username"
                name="username"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.user.username.$error }"
              />
              <div
                v-if="submitted && !$v.user.username.required"
                class="invalid-feedback"
              >
                Username is required
              </div>
            </div>
            <div class="form-group">
              <label for="lastName">Phone number</label>
              <input
                type="text"
                v-model="user.phone"
                id="phone"
                name="phone"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.user.phone.$error }"
              />
              <div
                v-if="submitted && !$v.user.phone.required"
                class="invalid-feedback"
              >
                Phone number is required
              </div>
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                v-model="user.email"
                id="email"
                name="email"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.user.email.$error }"
              />
              <div
                v-if="submitted && $v.user.email.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.user.email.required">Email is required</span>
                <span v-if="!$v.user.email.email">Email is invalid</span>
              </div>
            </div>

            <CSelect
              label="Role"
              :value.sync="user.role"
              :options="['admin', 'support']"
            />

            <div class="form-group">
              <label for="password">Password</label>
              <input
                type="password"
                v-model="user.password"
                id="password"
                name="password"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.user.password.$error }"
              />
              <div
                v-if="submitted && $v.user.password.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.user.password.required"
                  >Password is required</span
                >
                <span v-if="!$v.user.password.minLength"
                  >Password must be at least 6 characters</span
                >
              </div>
            </div>

            <div class="form-group">
              <label for="confirmPassword">Confirm Password</label>
              <input
                type="password"
                v-model="user.confirmPassword"
                id="confirmPassword"
                name="confirmPassword"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.user.confirmPassword.$error,
                }"
              />
              <div
                v-if="submitted && $v.user.confirmPassword.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.user.confirmPassword.required"
                  >Confirm Password is required</span
                >
                <span v-else-if="!$v.user.confirmPassword.sameAsPassword"
                  >Passwords must match</span
                >
              </div>
            </div>
            <div class="form-group">
              <button class="btn btn-primary">Add User</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <CToaster :autohide="3000">
      <template v-for="t in fixedToasts">
        <CToast :key="'toast' + t" :show="true" :header="toast.title">
          <span :class="toast.error ? 'text-danger' : ''">
            {{ toast.msg }}
          </span>
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import UserServiceApi from "@/services/api/users";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: "app",
  data() {
    return {
      user: {
        username: "",
        phone: "",
        email: "",
        role: "admin",
        password: "",
        confirmPassword: "",
      },
      toast: {
        show: false,
        title: "",
        msg: "",
        error: false,
      },
      submitted: false,
      fixedToasts: 0,
    };
  },
  validations: {
    user: {
      username: { required },
      phone: { required },
      email: { required, email },
      role: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    },
  },
  methods: {
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.createUser(this.user);
    },
    createUser(data) {
      UserServiceApi.registerAdmin(data)
        .then(() => {
          this.toast.title = "Admin registration";
          this.toast.msg = "Admin registered successfully!";
          this.toast.error = false;
          this.addFixedToast();
        })
        .catch((err) => {
          console.log(err.response.data.message);
          this.toast.title = "Admin registration";
          this.toast.msg = err.response.data.message;
          this.toast.error = true;
          this.addFixedToast();
        });
    },
    addFixedToast() {
      this.fixedToasts++;
    },
  },
};
</script>